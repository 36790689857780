.App {
  text-align: center;
}
main{
  /* background-color: #ffffff !important; */
  padding:0 !important;
}
.mantine-Modal-title{
  font-weight: bold;
}
.vjs-poster {
  background-size: cover !important;
}